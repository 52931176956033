import React, { useEffect, useState } from "react";
import { Document, Packer, Paragraph, TextRun } from "docx";
import { saveAs } from "file-saver";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import Swal from "sweetalert2";

import ActionSheet from "./ActionSheet";
import Editor from "./Editor";
import Flows from "./Flows";
import Fog from "./Fog";
import Toolbar from "./Toolbar";
import Freemium from "./hocs/Freemium";
import * as CONSTANTS from "./utils/constants";
import * as firebaseHelpers from "./utils/firebaseHelpers";
import * as helpers from "./utils/helpers";
import {
  ClipboardList,
  Document as DocumentIcon,
  Export,
  Filter,
  User,
} from "./utils/icons";

import "react-toastify/dist/ReactToastify.css";
import "sweetalert2/dist/sweetalert2.min.css";
import "./App.css";

function App() {
  const [loadedAuth, setLoadedAuth] = useState(false);
  const [signedIn, setSignedIn] = useState(false);
  const [userPreferences, setUserPreferences] = useState(null);
  const [value, setValue] = useState("");
  const [showFlows, setShowFlows] = useState(false);
  const [dailyPrompt, setDailyPrompt] = useState(null);
  const [dailyPromptActive, setDailyPromptActive] = useState(false);
  // For more info, see here:
  // https://firebase.google.com/docs/reference/js/firebase.firestore.DocumentReference)
  const [docRef, setDocRef] = useState(null);

  useEffect(() => {
    window.firebaseAuth.onAuthStateChanged(
      function (user) {
        if (user) {
          setSignedIn(true);
          firebaseHelpers.getUserPreferences(setUserPreferences);
          // firebaseHelpers.getDailyPrompt(setDailyPrompt);
        } else if (window.firebaseAuthUI.isPendingRedirect()) {
          showAuth();
        }

        setLoadedAuth(true);
      },
      function (error) {
        console.log(error);
      }
    );

    if (
      localStorage.getItem("software_version") !== CONSTANTS.SOFTWARE_VERSION
    ) {
      showSoftwareUpdates();
    }

    if (
      localStorage.getItem("instructions_version") !==
      CONSTANTS.INSTRUCTIONS_VERSION
    ) {
      showInstructions();
    }

    const breathingRoomUrls = [
      "/breathingroom",
      "/breathingroompod",
      "/breathingroompodcast",
      "/thebreathingroom",
      "/thebreathingroompod",
      "/thebreathingroompodcast",
    ];

    const breathingRoomPrompt = {
      body: "What's one thing that people typically misunderstand about you? Why?",
      releaseDate: "2-24-2021",
      id: "Fg6xacLmTFMgZHdSbJV2",
    };

    if (breathingRoomUrls.includes(window.location.pathname)) {
      setDailyPrompt(breathingRoomPrompt);
      activateDailyPrompt();
    }
  }, []);

  useEffect(() => {
    if (dailyPromptActive || showFlows) {
      toast.dismiss("dailyPrompt");
    } else {
      if (dailyPrompt) {
        toast(
          <div>
            <div>{dailyPrompt.body}</div>
            <div className="App__daily-prompt-indicator">
              <span>Click to answer &middot; </span>
              <span className="App__daily-prompt-indicator-time-info">
                Daily Prompt
              </span>
            </div>
          </div>,
          {
            autoClose: false,
            className: "Toastify__toast--dailyPrompt",
            closeButton: true,
            closeOnClick: false,
            draggable: true,
            onClick: activateDailyPrompt,
            position: toast.POSITION.BOTTOM_CENTER,
            toastId: "dailyPrompt",
          }
        );
      }
    }
  }, [dailyPromptActive, dailyPrompt, showFlows]);

  useEffect(() => {
    if (
      userPreferences &&
      userPreferences.fontIdentifier != null &&
      CONSTANTS.FONTS[userPreferences.fontIdentifier] != null
    ) {
      document.body.style.fontFamily =
        CONSTANTS.FONTS[userPreferences.fontIdentifier];
    }
  }, [userPreferences]);

  function activateDailyPrompt() {
    setDailyPromptActive(true);
  }

  function deactivateDailyPrompt() {
    setDailyPromptActive(false);
  }

  let typingTimer;
  let doneTypingInterval = 750; // time in MS

  function onKeyUp() {
    if (signedIn) {
      clearTimeout(typingTimer);
      typingTimer = setTimeout(doneTyping, doneTypingInterval);
    }
  }

  function onKeyDown() {
    if (!dailyPromptActive && toast.isActive("dailyPrompt")) {
      toast.dismiss("dailyPrompt");
    }

    if (signedIn) {
      clearTimeout(typingTimer);
    }
  }

  function doneTyping() {
    const [dailyPromptId, dailyPromptBody] =
      dailyPrompt && dailyPromptActive
        ? [dailyPrompt.id, dailyPrompt.body]
        : [null, null];

    if (!docRef) {
      if (value.length > 0) {
        firebaseHelpers.makePost(
          value,
          setDocRef,
          dailyPromptId,
          dailyPromptBody
        );
      }
    } else {
      firebaseHelpers.updatePost(value, docRef, dailyPromptId, dailyPromptBody);
    }
  }

  const onChange = (e) => setValue(e.target.value);

  const generateFilename = () =>
    moment().format("YYYY-MM-DD hmma") + " - Floward";

  function downloadTextFile() {
    const element = document.createElement("a");
    const file = new Blob([value], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = generateFilename();
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  }

  function downloadMicrosoftWordFile() {
    const doc = new Document();

    doc.addSection({
      properties: {},
      children: [
        new Paragraph({
          children: [new TextRun(value)],
        }),
      ],
    });

    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, `${generateFilename()}.docx`);
    });
  }

  function signOut() {
    window.firebaseAuth
      .signOut()
      .then(function () {
        setSignedIn(false);
        Swal.close();
        toast.dismiss();
        toast.dark(CONSTANTS.TOAST_MESSAGES.AUTH.ON_USER_SIGN_OUT);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function showAuth() {
    Swal.fire({
      allowEnterKey: false,
      background: CONSTANTS.MODAL_BACKGROUND_COLOR,
      title: "Welcome to Floward",
      showCloseButton: true,
      showConfirmButton: false,
      html: '<div id="firebaseui-auth-container"></div>',
      footer: "🔒 Secure",
    });

    window.firebaseAuthUI.start("#firebaseui-auth-container", {
      callbacks: {
        signInSuccessWithAuthResult: () => {
          Swal.close();
          toast.dismiss();
          toast.success(CONSTANTS.TOAST_MESSAGES.AUTH.ON_USER_SIGN_IN);
          return false;
        },
        signInFailure: (error) => {
          console.log(error);
          Swal.close();
          toast.dismiss();
          toast.error(CONSTANTS.TOAST_MESSAGES.AUTH.ON_USER_SIGN_IN_FAILURE);
          // TODO: Sometimes folks add code here to handle anonymous user merge conflicts
          // Considering we don't allow anonymous users, I don't think we'll have that issue.
          // To be safe, I'm keeping this comment here for posterity's sake.
          return Promise.resolve();
        },
      },
      signInOptions: [
        {
          provider: window.firebase.auth.EmailAuthProvider.PROVIDER_ID,
          signInMethod:
            window.firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
          forceSameDevice: true,
        },
      ],
      // TODO: Add the following URLs
      // tosUrl and privacyPolicyUrl accept either url string or a callback function.
      // tosUrl: '<your-tos-url>',
      // privacyPolicyUrl: '<your-privacy-policy-url>',
    });
  }

  function getCustomizationWidget() {
    let config = {};
    for (let font of Object.keys(CONSTANTS.FONTS)) {
      const capitalizedFont = font.charAt(0).toUpperCase() + font.slice(1);

      config[capitalizedFont] = {
        onClick: (close) => () => {
          firebaseHelpers.setUserPreferences(font, setUserPreferences);
          close();
        },
        style: { fontFamily: CONSTANTS.FONTS[font] },
      };
    }

    return (
      <ActionSheet
        title={"Switch fonts"}
        config={config}
        renderClass={"ActionSheet__button"}
        renderIcon={Filter}
        renderText={"Customize"}
      />
    );
  }

  const settingsActionSheetConfig = {
    "Export Flow": {
      icon: Export,
      onClick: () => showDownload,
    },
    ...(signedIn && {
      // fancy JavaScript to conditionally add key
      Customize: {
        div: getCustomizationWidget(),
      },
    }),
    Instructions: {
      icon: ClipboardList,
      onClick: () => showInstructions,
    },
    "Release Notes": {
      icon: DocumentIcon,
      onClick: () => () => {
        window.open(CONSTANTS.RELEASE_NOTES_URL, "noopener");
      },
    },
    [signedIn ? "Sign out" : "Sign in"]: {
      icon: User,
      onClick: () => (signedIn ? signOut : showAuth),
    },
  };

  async function showInstructions() {
    const steps = ["1", "2", "3"];

    const swalQueueStep = Swal.mixin({
      confirmButtonText: "Next &rarr;",
      cancelButtonText: "&larr; Back",
      progressSteps: steps,
      reverseButtons: true,
    });

    for (let currentStep = 0; currentStep < steps.length; ) {
      const { videoEmbedUrl, title, subtitle } =
        CONSTANTS.INSTRUCTIONS[currentStep];

      const result = await swalQueueStep.fire({
        html: helpers.generateInstruction(videoEmbedUrl, title, subtitle),
        title: `How This Works`,
        width: "42em",
        showCancelButton: currentStep > 0,
        showCloseButton: true,
        currentProgressStep: currentStep,
        footer:
          "You can find these instructions again from the settings panel in the top-right corner.",
        willClose: () => {
          localStorage.setItem(
            "instructions_version",
            CONSTANTS.INSTRUCTIONS_VERSION
          );
        },
      });

      if (result.value) {
        currentStep++;
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        currentStep--;
      } else {
        break;
      }
    }
  }

  function showSoftwareUpdates() {
    toast.dismiss();
    toast.info(
      () => (
        <span className="App__software-update-notification">
          You're using the latest version of Floward (
          {CONSTANTS.SOFTWARE_VERSION}). See{" "}
          <a
            href={CONSTANTS.RELEASE_NOTES_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            what's new
          </a>
          .
        </span>
      ),
      {
        autoClose: 10000,
        closeButton: true,
        closeOnClick: false,
        draggable: true,
        onClose: () => {
          localStorage.setItem("software_version", CONSTANTS.SOFTWARE_VERSION);
        },
        pauseOnHover: true,
        position: toast.POSITION.TOP_CENTER,
      }
    );
  }

  function showDownload() {
    const exportOptions = {
      ".txt": {
        label: "Text file (.txt)",
        handler: downloadTextFile,
      },
      ".docx": {
        label: "Microsoft Word Doc (.docx)",
        handler: downloadMicrosoftWordFile,
      },
    };

    const inputOptions = {};
    for (const exportType in exportOptions) {
      inputOptions[exportType] = exportOptions[exportType].label;
    }

    Swal.fire({
      allowEnterKey: false,
      background: CONSTANTS.MODAL_BACKGROUND_COLOR,
      width: "42em",
      title: "Download as...",
      showCloseButton: true,
      input: "select",
      inputValue: Object.keys(inputOptions)[0],
      inputOptions: inputOptions,
      inputValidator: (savePreference) => {
        return new Promise((resolve) => {
          Object.keys(exportOptions).includes(savePreference)
            ? resolve()
            : resolve("You need to select a preference from the dropdown 🙏🏽");
        });
      },
      preConfirm: (savePreference) => {
        if (value.length === 0) {
          toast.dismiss();
          toast.dark(CONSTANTS.TOAST_MESSAGES.EDITOR.ON_PREMATURE_SAVE_ATTEMPT);
        } else {
          exportOptions[savePreference].handler();
        }
      },
    });
  }

  const customFont = {
    ...(userPreferences &&
      userPreferences.fontIdentifier != null &&
      CONSTANTS.FONTS[userPreferences.fontIdentifier] != null && {
        fontFamily: CONSTANTS.FONTS[userPreferences.fontIdentifier],
      }),
  };

  const app = (
    <div className="App" style={customFont}>
      <ToastContainer newestOnTop={true} position="top-center" />
      <Toolbar
        loadedAuth={loadedAuth}
        signedIn={signedIn}
        showAuth={showAuth}
        showFlows={showFlows}
        settingsActionSheetConfig={settingsActionSheetConfig}
        setShowFlows={setShowFlows}
      />
      {showFlows ? (
        <Flows />
      ) : (
        <>
          <Fog
            dailyPrompt={
              dailyPrompt &&
              dailyPromptActive && (
                <div>
                  <div
                    onClick={deactivateDailyPrompt}
                    className="App__daily-prompt"
                  >
                    <div className="App__daily-prompt-body">
                      {dailyPrompt.body}
                    </div>
                    <div className="App__daily-prompt-action">
                      Click to remove
                    </div>
                  </div>
                  <a
                    href="https://podcasts.apple.com/us/podcast/ep-07-distraction-free-journalling-feat-benjamin-williams/id1566898126?i=1000525025048"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="App__daily-prompt-podcast-link"
                  >
                    <div className="App__daily-prompt-podcast-content">
                      <div className="App__daily-prompt-podcast-content-title">
                        ▶ Discover the inspiration for this Daily Prompt
                      </div>
                      <div className="App__daily-prompt-podcast-content-subtitle">
                        The Breathing Room on Apple Podcasts
                      </div>
                    </div>
                  </a>
                </div>
              )
            }
          />
          <Editor
            onChange={onChange}
            onKeyDown={onKeyDown}
            onKeyUp={onKeyUp}
            value={value}
            toast={toast}
          />
        </>
      )}
    </div>
  );

  if (!loadedAuth) {
    return null;
  } else if (signedIn) {
    return app;
  } else {
    return <Freemium content={app} showAuth={showAuth} />;
  }
}

export default App;
