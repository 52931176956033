import React, { useEffect, useState } from 'react';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import * as CONSTANTS from './utils/constants';
import { DateWithCheckmark } from './utils/icons';
import * as firebaseHelpers from './utils/firebaseHelpers';

import './Flows.css';

function Flows(props) {
    const MySwal = withReactContent(Swal);

    const [flows, setFlows] = useState([]);
    const [hasFetchedFlows, setHasFetchedFlows] = useState(false);
    const [hasMoreFlowsToFetch, setHasMoreFlowsToFetch] = useState(true);

    useEffect(() => {
        firebaseHelpers.getPosts((initialFlows) => {
            setFlows(initialFlows);
            setHasFetchedFlows(true);
            if (initialFlows.length < CONSTANTS.FLOWS_CHUNK_SIZE) {
                stopFutureFetches();
            }
        });

        return () => {
            delete window.getUserPosts;
        };
    }, []);

    function truncateString(str, numChars) {
        if (str.length <= numChars) {
            return str;
        }

        return str.slice(0, numChars) + '...';
    }

    const showFlow = (body, dailyPromptBody, updated) => () => {
        MySwal.fire({
            background: CONSTANTS.MODAL_BACKGROUND_COLOR,
            allowEnterKey: false,
            width: '42em',
            showCloseButton: true,
            showConfirmButton: false,
            html: (
                <div>
                    {dailyPromptBody &&
                        <h1 className="Flows__modal-prompt">{dailyPromptBody}</h1>
                    }
                    <p className='Flows__modal-body'>{body}</p>
                </div>
            ),
            footer: (
                // last updated
                <span className='Flows__modal-last-updated'>
                    {moment(updated.toDate()).fromNow().toLowerCase()}
                </span>
            ),
        });
    };

    function appendFlows(newFlows) {
        setFlows([...flows, ...newFlows]);
    };

    function stopFutureFetches() {
        setHasMoreFlowsToFetch(false);
    }

    const getPostsGenerator = (appendResultCallback, stopFutureFetchesCallback) => () => {
        firebaseHelpers.getPosts(appendResultCallback, stopFutureFetchesCallback);
    };

    const getPosts = getPostsGenerator(appendFlows, stopFutureFetches);

    return (
        <div className='Flows'>
            <div className='Flows__overview'>
                <div className='Flows__overview-container'>
                    <div className='Flows__overview-body'>Your Flows</div>
                </div>
            </div>
            {hasFetchedFlows && (flows.length > 0 ?
                (<InfiniteScroll
                    className="Flows__list"
                    dataLength={flows.length} // This is important field to render the next data
                    next={getPosts}
                    hasMore={hasMoreFlowsToFetch}
                >
                    {flows.map((flow, index) => (
                        <div key={index} onClick={showFlow(flow.body, flow.dailyPromptBody, flow.updated)} className='Flows__item'>
                            {truncateString(flow.body, 200)}
                            {flow.dailyPromptId && 
                                <div className='Flows__daily-prompt-complete-indicator'>{DateWithCheckmark}</div>
                            }
                        </div>
                    ))}
                </InfiniteScroll>) :
                (<div className='Flows__empty-list'>You haven't created any Flows, yet.</div>)
            )}
        </div>
    );
}

export default Flows;
