import moment from 'moment';

import * as CONSTANTS from './constants';

export function makePost(body, setDocRef, dailyPromptId, dailyPromptBody) {
    window.firebaseDb.collection("posts").add({
        created: window.firebase.firestore.FieldValue.serverTimestamp(),
        updated: window.firebase.firestore.FieldValue.serverTimestamp(),
        uid: window.firebaseAuth.currentUser.uid,
        body,
        ...(dailyPromptId != null && { dailyPromptId }), // fancy JavaScript to conditionally add key
        ...(dailyPromptBody != null && { dailyPromptBody }), // fancy JavaScript to conditionally add key
    })
        .then(function (docRef) {
            console.log("Document written with ID: ", docRef.id);

            setDocRef(docRef.id);
        })
        .catch(function (error) {
            console.error("Error adding document: ", error);
        });
}

export function updatePost(body, docRefId, dailyPromptId, dailyPromptBody) {
    window.firebaseDb.collection("posts").doc(docRefId).update({
        updated: window.firebase.firestore.FieldValue.serverTimestamp(),
        uid: window.firebaseAuth.currentUser.uid,
        body,
        dailyPromptId: dailyPromptId ? dailyPromptId : window.firebase.firestore.FieldValue.delete(),
        dailyPromptBody: dailyPromptBody ? dailyPromptBody : window.firebase.firestore.FieldValue.delete()
    })
        .then(function () {
            console.log("Document updated with ID: ", docRefId);
        })
        .catch(function (error) {
            console.error("Error adding document: ", error);
        });
}

export function getPosts(appendResultingData, stopFutureFetches) {
    if (!window.getUserPosts) {
        window.getUserPosts = {};
        window.getUserPosts.next = window.firebaseDb.collection("posts")
            .where("uid", "==", window.firebaseAuth.currentUser.uid)
            .orderBy("updated", "desc")
            .limit(CONSTANTS.FLOWS_CHUNK_SIZE);
    }

    window.getUserPosts.next.get().then(function (documentSnapshots) {
        const newPosts = documentSnapshots.docs.map(doc => doc.data());

        appendResultingData(newPosts);

        if (newPosts.length < CONSTANTS.FLOWS_CHUNK_SIZE) {
            stopFutureFetches();
            return;
        }

        const lastVisible = documentSnapshots.docs[documentSnapshots.docs.length - 1];
        window.getUserPosts.next = window.getUserPosts.next.startAfter(lastVisible);
    }).catch(function (error) {
        console.log("Error getting documents: ", error);
    });
}

export function getDailyPrompt(setResult) {
    window.firebaseDb.collection("daily-prompts")
        .where("releaseDate", "==", moment().format("M-D-YYYY").toString())
        .get()
        .then(function (querySnapshot) {
            const result = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setResult(result[0]);
        })
        .catch(function (error) {
            console.log("Error getting daily prompt: ", error);
        });
}

export function getUserPreferences(setResult) {
    const fontIdentifier = localStorage.getItem('fontIdentifier');

    if (fontIdentifier) {
        setResult({ fontIdentifier });
    } else {
        window.firebaseDb.collection("user-preferences")
            .where("uid", "==", window.firebaseAuth.currentUser.uid)
            .get()
            .then(function (querySnapshot) {
                const result = querySnapshot.docs.map(doc => ({ ...doc.data() }));

                if (result.length) {
                    setResult(result[0]);
                    localStorage.setItem('fontIdentifier', result[0].fontIdentifier);
                }
            })
            .catch(function (error) {
                console.log("Error getting user preferences: ", error);
            });
    }
}

// fontIdentifier is the key in a fontIdentifier -> fontFamily mapping.
// This mapping lets us change the underlying fontFamily for users
// without updating the database.
export async function setUserPreferences(fontIdentifier, setResult) {
    if (!(fontIdentifier in CONSTANTS.FONTS)) {
        console.log("Invalid font selection"); // TODO: create an error component
    } else {
        window.firebaseDb.collection("user-preferences")
            .where("uid", "==", window.firebaseAuth.currentUser.uid)
            .get()
            .then(function (querySnapshot) {
                const result = querySnapshot.docs.map(doc => ({ id: doc.id }));

                if (result && result.length) {
                    window.firebaseDb.collection("user-preferences").doc(result[0].id).update({ fontIdentifier })
                        .then(function () {
                            const result = { fontIdentifier };
                            setResult(result);
                            localStorage.setItem('fontIdentifier', fontIdentifier);
                        })
                        .catch(function (error) {
                            console.error("Error adding document: ", error);
                        });
                } else {
                    window.firebaseDb.collection("user-preferences").add({
                        fontIdentifier,
                        uid: window.firebaseAuth.currentUser.uid,
                    })
                        .then(function (docRef) {
                            console.log("Document written with ID: ", docRef.id);
        
                            const result = { fontIdentifier };
                            setResult(result);
                            localStorage.setItem('fontIdentifier', fontIdentifier);
                        })
                        .catch(function (error) {
                            console.error("Error adding document: ", error);
                        });
                }
            })
            .catch(function (error) {
                console.log("Error getting user preferences: ", error);
            });
    }
}

// TODO: unused functions

// export function getPost(id) {
//     window.firebaseDb.collection("posts").doc(id)
//         .get()
//         .then(function(doc) {
//             if (doc.exists) {
//                 console.log("Document data:", doc.data());
//             } else {
//                 // doc.data() will be undefined in this case
//                 console.log("No such document!");
//             }
//         }).catch(function(error) {
//             console.log("Error getting document:", error);
//         });
// }

// export function getAllPosts(setResult, setFinishedLoading) {
//     window.firebaseDb.collection("posts")
//         .where("uid", "==", window.firebaseAuth.currentUser.uid)
//         .get()
//         .then(function (querySnapshot) {
//             const result = querySnapshot.docs.map(doc => doc.data());
//             const sortedResult = result.sort((first, second) => (first.updated - second.updated));
//             setResult(sortedResult);
//             setFinishedLoading(true);
//         })
//         .catch(function (error) {
//             console.log("Error getting documents: ", error);
//         });
// }
