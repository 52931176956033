import React from 'react'; // { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import * as CONSTANTS from './utils/constants';

import './ActionSheet.css';

function ActionSheet(props) {
    const MySwal = withReactContent(Swal);

    const title = props.title;
    
    const show = () => {
        MySwal.fire({
            title,
            html: (
                <div>
                    {Object.keys(props.config).map((actionName) => (props.config[actionName].div ? props.config[actionName].div :
                        (<button
                            className={`ActionSheet__button ${props.config[actionName].className}`}
                            onClick={props.config[actionName].onClick(MySwal.close)}
                            style={props.config[actionName].style}
                        >
                            {props.config[actionName].icon}{actionName}
                        </button>)
                    ))}
                </div>
            ),
            showConfirmButton: false,
            background: CONSTANTS.MODAL_BACKGROUND_COLOR,
        });
    };

    return (
        <button className={`ActionSheet__trigger-button ${props.renderClass}`} onClick={show}>{props.renderIcon}{props.renderText}</button>
    );
}

export default ActionSheet;
