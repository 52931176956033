export const RELEASE_NOTES_URL = 'https://docs.google.com/document/d/1LofE87evbwt9qOG5dkWX98RyiHqSTcOBaQVvfp_VaVQ/edit?usp=sharing';
export const INSTRUCTIONS_VERSION = "0.1.0";
export const SOFTWARE_VERSION = "0.4.0";

export const TOAST_MESSAGES = {
    AUTH: {
        ON_USER_SIGN_IN: "Welcome Home 💛",
        ON_USER_SIGN_IN_FAILURE: `Something went wrong. This usually doesn't happen 😕. Please 
            try again shortly, and if the issue persists, reach out to support, [TODO].`,
        ON_USER_SIGN_OUT: "💔 Come back soon, OK?",
    },
    EDITOR: {
        // When the user attempts to save/export, but nothing is written!
        ON_PREMATURE_SAVE_ATTEMPT: 'You must first write something before you can download 🤓'
    },
};

export const MODAL_BACKGROUND_COLOR = '#F5F5F7';

export const INSTRUCTIONS = [
    {
        videoEmbedUrl: 'https://www.youtube.com/embed/xF8SpE26YDs',
        title: 'Write without hesitation',
        subtitle: 'Floward encourages writing and discourages editing your thoughts.'
    },
    {
        videoEmbedUrl: 'https://www.youtube.com/embed/uOHKGzHy4bw',
        title: 'Saving made simple',
        subtitle: `You can manually export your Flows to .txt and Microsoft Word files. With an account, your Flows will autosave and sync across your devices.`,
    },
    {
        videoEmbedUrl: 'https://www.youtube.com/embed/OsFaSD6NGRE',
        title: 'Daily Prompts',
        subtitle: `With an account, you'll recieve a thought-provoking daily prompt at midnight.`
    },
]

// The max number of Flows that will be returned per request to getFlows
export const FLOWS_CHUNK_SIZE = 10;

export const FONTS = {
    'typewriter': "Georgia, 'Times New Roman', Times, serif",
    'script': "'Yellowtail', cursive",
    'handwritten': "'Nanum Pen Script', cursive",
};
