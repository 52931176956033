import './Fog.css';

function Fog(props) {
    return (
        <div className="Fog">
            <div className="Fog__line-top" />
            {props.dailyPrompt}
            <div className="Fog__line" />
        </div>
    );
}

export default Fog;
