import React, { useRef, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';

import './Editor.css';

function Editor(props) {
    const textareaRef = useRef(null);
    const [showError, setShowError] = useState(false);
    const [isFocused, setIsFocused] = useState(false);

    function isValidKeyPress(e) {
        const invalidKeys = ['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'];

        return !invalidKeys.includes(e.key);
    }

    function showErrorState() {
        setShowError(true);
        setTimeout(() => {
            setShowError(false);
        }, 1000);
    };

    function blurInput() {
        setIsFocused(false);
    }

    const onKeyDown = e => {
        props.onKeyDown();

        if (!isValidKeyPress(e)) {
            showErrorState();
            e.preventDefault();
        }
    };

    const onKeyUp = e => {
        if (isValidKeyPress(e)) {
            props.onKeyUp();
        }
    };

    const placeCursorAtEnd = e => {
        if (props.value.length > 0 && (e.type === "click" || e.type === "touchend") && isFocused) {
            showErrorState();
        }

        textareaRef.current.setSelectionRange(props.value.length, props.value.length);

        if (e.type === "click") {
            setIsFocused(true);
        }
    };

    return (
        <TextareaAutosize
            autoFocus
            className={`Editor ${showError ? 'Editor--error' : ''}`}
            onChange={props.onChange}
            onKeyDown={onKeyDown}
            onKeyUp={onKeyUp}
            placeholder="Type something..."
            ref={textareaRef}
            value={props.value}
            onClick={placeCursorAtEnd}
            onContextMenu={placeCursorAtEnd}
            onDoubleClick={placeCursorAtEnd}
            onDrag={placeCursorAtEnd}
            onDragEnd={placeCursorAtEnd}
            onDragEnter={placeCursorAtEnd}
            onDragExit={placeCursorAtEnd}
            onDragLeave={placeCursorAtEnd}
            onDragOver={placeCursorAtEnd}
            onDragStart={placeCursorAtEnd}
            onDrop={placeCursorAtEnd}
            onMouseDown={placeCursorAtEnd}
            onMouseEnter={placeCursorAtEnd}
            onMouseLeave={placeCursorAtEnd}
            onMouseMove={placeCursorAtEnd}
            onMouseOut={placeCursorAtEnd}
            onMouseOver={placeCursorAtEnd}
            onMouseUp={placeCursorAtEnd}
            onPointerDown={placeCursorAtEnd}
            onPointerMove={placeCursorAtEnd}
            onPointerUp={placeCursorAtEnd}
            onPointerCancel={placeCursorAtEnd}
            onGotPointerCapture={placeCursorAtEnd}
            onLostPointerCapture={placeCursorAtEnd}
            onPointerEnter={placeCursorAtEnd}
            onPointerLeave={placeCursorAtEnd}
            onPointerOver={placeCursorAtEnd}
            onPointerOut={placeCursorAtEnd}
            onSelect={placeCursorAtEnd}
            onTouchCancel={placeCursorAtEnd}
            onTouchEnd={placeCursorAtEnd}
            onTouchMove={placeCursorAtEnd}
            onTouchStart={placeCursorAtEnd}
            onBlur={blurInput}
        />
    );
}

export default Editor;
