import Waves from '../Waves';

import './Freemium.css';

function Freemium({ content, showAuth }) {
    return (
        <div className='Freemium'>
            <div className='Freemium__child Freemium__Ad'>
                <img className='Freemium__floward-logo' src='logo.png' alt='Floward logo' />
                <div className='Freemium__Ad-container'>
                    <h2 className='Freemium__floward-subtitle'>Sign up today.</h2>
                    <h3 className='Freemium__floward-feature'>
                        <span className='Freemium__floward-icon'>☁️</span>Auto-save and auto-sync your Flows across all your devices.
                    </h3>
                    <h3 className='Freemium__floward-feature'>
                        <span className='Freemium__floward-icon'>🧠</span>Daily, thought-provoking journal prompts.
                    </h3>
                    <h3 className='Freemium__floward-feature'>
                        <span className='Freemium__floward-icon'>🎨</span>Personalize and customize your journal.
                    </h3>
                    <button className='Freemium__signup' onClick={showAuth}>Sign up</button>
                </div>
                <Waves />
            </div>
            <div className='Freemium__child'>
                {content}
            </div>
        </div>
    );
}

export default Freemium;
