export function generateInstruction(videoEmbedUrl, title, subtitle) {
    return `
        <div class='App__instructions'>
            <div class='App__video-container'>
                <iframe
                    width="560"
                    height="315"
                    src="${videoEmbedUrl}"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                >
                </iframe>
            </div>
            <h2>${title}</h2>
            <p>${subtitle}</p>
        <div>
    `;
}
