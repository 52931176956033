import './Waves.css';

function Waves() {
    return (
        <div className='box'>
            <div className='wave wave--one'></div>
            <div className='wave wave--two'></div>
            <div className='wave wave--three'></div>
        </div>
    );
}

export default Waves;
