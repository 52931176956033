import ActionSheet from './ActionSheet';
import { Add, Grid, Settings, User } from './utils/icons';

import './Toolbar.css';

function Toolbar(props) {
    function toggleShowFlows() {
        props.setShowFlows(!props.showFlows);
    }

    return (
        <div className='Toolbar'>
            <div className='Toolbar__left-side'>
                {props.loadedAuth && !props.signedIn &&
                    <button className='Toolbar__button' onClick={props.showAuth}>
                        {User}Sign in
                    </button>
                }
                {props.signedIn &&
                    <button className='Toolbar__button' onClick={toggleShowFlows}>
                        {props.showFlows ?  <>{Add}Flow</> :  <>{Grid}Your Flows</>}
                    </button>
                }
            </div>
            <div className='Toolbar__right-side'>
                <ActionSheet
                    title={'Settings'}
                    config={props.settingsActionSheetConfig}
                    renderClass={'Toolbar__button'}
                    renderIcon={Settings}
                    renderText='More'
                />
            </div>
        </div>
    );
}

export default Toolbar;
